import { IUser } from './user'
import { IRequiredReport } from './requiredReport'
import { FinancialsType, IBorrowingBase, IOveradvance } from './bbc'
import { ITermLoan } from './termLoan'
import { FeeType, IFee } from './loanServicing'
import { IClientEntityRelationship } from './integration'
import { IOPSReporting } from './prospects'
import { LOAN_TYPES } from '../constants/client'
import { IParticipation, IParticipationLoanExposure, IParticipationWire } from './participant'
import { IDueDiligenceInfo } from './dueDiligence'
import { IBankAccount } from './bankAccount'

export enum AccountActivityPeriods {
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Annually = 'Annually',
}

export const ACCOUNT_ACTIVITY_PERIOD_OPTIONS = Object.values(AccountActivityPeriods).map(
  (value) => ({
    value,
    label: value,
  }),
)

export enum ClientERPSystem {
  Netsuite = 'Oracle Netsuite',
  QuickbooksOnline = 'Quickbooks Online',
  QuickbooksDesktop = 'Quickbooks Desktop',
  Other = 'Other(s)',
}

export const SALESFORCE_ERP_SYSTEM_ACCOUNT_NAMES_MAPPING = {
  [ClientERPSystem.Netsuite]: 'NetSuite',
  [ClientERPSystem.QuickbooksOnline]: 'Quickbooks',
  [ClientERPSystem.QuickbooksDesktop]: 'Quickbooks',
}

export const SALESFORCE_ERP_SYSTEM_ACCOUNT_TYPES_MAPPING = {
  [ClientERPSystem.QuickbooksOnline]: {
    type: 'SaaS/Tech',
    subType: 'ERP',
  },
}

export const ERP_SYSTEM_RELATIONSHIP_SALESFORCE_DESCRIPTION = {
  Prospect: 'Added from Client Intake: ERP System',
  'Due Diligence': 'Added from Underwriting application: ERP System',
}

export enum ClientInfoStatus {
  Active = 'Active',
  Prospect = 'Prospect',
  DueDiligence = 'Due Diligence',
  Past = 'Past',
  PastArchived = 'Past / Archived',
  Archived = 'Prospect / Archived',
  Current = 'Current',
}

export enum CodatSyncStatus {
  Success = 'Success',
  Failed = 'Failed',
}

export const CLIENT_LOAN_BALANCE_STATUSES = [
  ClientInfoStatus.Current,
  ClientInfoStatus.Past,
  ClientInfoStatus.PastArchived,
]

export const LOAN_BALANCE_STATUSES_WITH_DD = [
  ...CLIENT_LOAN_BALANCE_STATUSES,
  ClientInfoStatus.DueDiligence,
]

export enum SalesforceStage {
  Rejected = 'Rejected',
  Archived = 'Archived',
  TrackingEarly = 'Tracking-Too Early',
  ClosedLost = 'Closed Lost',
  ClosedWon = 'Closed Won',
  Dead = 'Dead',
  DueDiligence = 'Due Diligence',
  Funding = 'Funding',
  ContractNegotiation = 'Contract Negotiation',
  Closed = 'Closed',
  Convert = 'Convert',
  TermSheetNegotiation = 'Term Sheet Negotiation',
  OpsCall = 'OPS Call',
  Submission = 'Committee Submission',
  Passed = 'Passed',
  IntroOrMention = 'Intro or Mention',
  GatheringInfo = 'Gathering Info',
  QualifyingCall = 'Qualifying Call',
}

export interface IClientIntakeInfo {
  id: string
  clientName: string
  fullLegalName: string | null
  employeeCount: number | null
  billingPostalCode: string | null
  billingState: string | null
  billingCity: string | null
  billingCountry: string | null
  billingStreet: string | null
  establishedDate: string | null
  phone: string | null
  stateOfFormation: string | null
  federalTaxId: string | null
  primaryContactEmail: string | null
  primaryContactPhone: string | null
  primaryContactFirstName: string | null
  primaryContactLastName: string | null
  erpSystem: ClientERPSystem[]
  erpSystemOther: string | null
}

export interface IClientInfo {
  id: string
  accountId?: string
  leadId?: string
  opportunityId?: string
  salesforceLink?: string
  clientName?: string
  contractDate?: string
  maturityDate?: string
  industry?: string
  contractDocumentsFolderId?: string
  loanType: string
  pastInvoiceThreshold: number
  crossAge: number
  terminationStartDate?: string
  terminationYear1: number
  terminationYear2: number
  clientStatus: ClientInfoStatus
  isPast?: boolean
  lastBBC?: string
  lastBBCNotify?: boolean
  recentFinancials?: string
  recentFinancialsNotify?: boolean
  recentTransactions?: string
  recentTransactionsNotify?: boolean
  nextUnderwrite?: string
  nextUnderwriteNotify?: boolean
  currentAmount?: number
  totalParticipantExposureAmount?: number
  participantContractualExposure?: number
  participationContractDate?: string
  totalNFE?: number
  termLoans?: ITermLoan[]
  primaryManager?: IUser
  primaryClientContact?: IUser
  maxLineAmount?: number
  minimumLineAmount?: number
  unusedLineFee?: number
  excessLoanAvailability?: string
  risk?: number
  requiredReports?: IRequiredReport[]
  bbcDocuments?: IRequiredReport[]
  minimumInterestEffectiveDate: string
  ongoingReportingDocuments?: IRequiredReport[]
  arAdvance: number
  inventoryAdvanceRateCost: number
  inventoryAdvanceRateNolv: number
  interestRate?: number
  interestRateBase?: number
  interestRateAbovePrime?: number
  futureInterestRateBase?: number
  futureInterestRateAbovePrime?: number
  futureClientRateChangeDate?: string
  nickname: string
  lockboxFee: number
  wireFee: number
  payoffDate?: string
  payoffAmount?: number
  payoffFees?: IClientPayoffFee[]
  payoffInterest?: number
  payoffInterestMinimum?: number
  payoffInterestTotal?: number
  payoffFloatBalance?: number
  payoffLoanBalance?: number
  applyLockboxFee?: boolean
  overrideTerminationFee?: boolean
  applyServicingFee?: boolean
  overridePayoffInterest?: boolean
  manualPayoffInterestAmount?: number
  terminationFee?: number
  servicingFee?: number
  nolv?: number
  gracePeriod?: number
  lastAnnualAppraisal?: string
  lastAnnualFieldExam?: string
  inventoryConverterTemplateId?: string | null
  itemsToMonitor?: string
  inventoryIneligibilityFieldAliases?: IInventoryIneligibilityFieldAliases
  codatId?: string
  isErpConnected?: boolean
  codatSyncStatus: CodatSyncStatus | null
  codatSyncedAt: string | null
  erpName: string
  coiExpirationDate?: string
  eopExpirationDate?: string
  lenderLossPayee?: boolean
  additionallyInsured?: boolean
  archivedAt: string

  phone?: string
  iconUrl?: string
  website?: string

  employeeCount?: number

  outsideLegalCounsel?: string

  stateOfFormation?: string

  federalTaxId?: string
  establishedDate?: string
  fullLegalName?: string

  billingPostalCode?: string
  billingState?: string
  billingCity?: string
  billingCountry?: string
  billingStreet?: string
  excluded?: boolean
  codatLink?: string
  isReportingOverdue?: boolean
  totalLoanBalance?: number
  lineTurn?: number
  termLoanBalance?: number
  erpSystem?: ClientERPSystem[]
  erpSystemOther?: string
  clientEntityRelationship?: IClientEntityRelationship[]
  opsReportings?: IOPSReporting[]
  clientIntakeInfo?: IClientIntakeInfo[]
  riskRating?: number
  availableAR?: number
  availableInventory?: number
  firstOPSReportingId?: string

  subIndustry: string
  salesChannels: string
  city: string
  state: string
  loanBalance: { loanBalance: number }

  refreshedAt: string | null
  clientInfoUsers: IUser[]
  ddInfo?: IDueDiligenceInfo

  bankAccountInfos?: IBankAccount[]
}

export interface IClientPayoffFee {
  type: FeeType
  title: string
  amount: number
}

export interface IClientsAggregation {
  data: IClientInfo[]
  totals: {
    totalItems: number
    totalLoanBalance: number
    nonClientCashBalance?: number
    totalMinimumLineAmount: number
    totalMaxLineAmount: number
    nfe: number
  }
}

export interface IAccountActivity {
  id: string
  type: string
  clientName: string
  clientInfo?: IClientInfo
  amount: number
  loanBalanceAtRecordDate: number
  loanBalanceEnding: number
  description: string | null
  notes: string | null
  recordDate: string
  feeId: string | null
  fee?: IFee
  boxLink?: string
  isEditable?: boolean
  isDeletable?: boolean
  isDeleted?: boolean
  isHistorical?: boolean
  borrowingBaseId: string | null
  borrowingBase?: IBorrowingBase
  originalMemo?: string
}

export interface IAggregationData {
  data: IAccountActivity[]
  totals: {
    totalItems: number
  }
}

export interface IAccountActivityGraphData {
  datasets: {
    label: string
    field: string
    data: number[]
  }[]
  labels: string[]
}

export interface IDeletedItems {
  data: any[]
  totals: {
    totalItems: number
  }
}

export interface IClientCompliance {
  id: string
  clientId: string
  clientName: string
  clientNameUrl?: string
  clientStatus: string
  loanType: string
  clientBalanceSheet: string
  clientBalanceSheetNextDueDate: string | null
  clientBalanceSheetStatus: string | null
  clientBalanceSheetStatusLabel: string
  clientBalanceSheetDaysSinceDue: number
  clientIncomeStatement: string
  clientIncomeStatementNextDueDate: string | null
  clientIncomeStatementStatus: string | null
  clientIncomeStatementStatusLabel: string
  clientFinancials: string
  clientFinancialsReporting: string
  clientFinancialsReportingAsOfDate: string
  latestClientFinancialsReportingId: string | null
  latestClientFinancialsReportingAsOfDate: string
  bankTransactions: string
  bankTransactionsNextDueDate: string | null
  bankTransactionsStatus: string | null
  bankTransactionsStatusLabel: string
  bankTransactionsReporting: string
  bankTransactionsReportingAsOfDate: string
  bankTransactionsDaysSinceDue: number
  latestBankTransactionsReportingId: string | null
  latestBankTransactionsReportingAsOfDate: string
  projections: string
  projectionsNextDueDate: string | null
  projectionsStatus: string | null
  projectionsStatusLabel: string
  projectionsReporting: string
  projectionsReportingAsOfDate: string
  projectionsDaysSinceDue: number
  latestProjectionsReportingId: string | null
  latestProjectionsReportingAsOfDate: string
  salesBySKU: string
  salesBySKUNextDueDate: string | null
  salesBySKUStatus: string | null
  salesBySKUStatusLabel: string
  salesBySKUReporting: string
  salesBySKUReportingAsOfDate: string
  salesBySKUDaysSinceDue: number
  latestSalesBySKUReportingId: string | null
  latestSalesBySKUReportingAsOfDate: string
  arLedger: string
  arLedgerNextDueDate: string | null
  arLedgerStatus: string | null
  arLedgerStatusLabel: string
  arLedgerReporting: string
  arLedgerReportingAsOfDate: string
  arLedgerDaysSinceDue: number
  latestArLedgerReportingId: string | null
  latestArLedgerReportingAsOfDate: string
  capTable: string
  capTableNextDueDate: string | null
  capTableStatus: string | null
  capTableStatusLabel: string
  capTableReporting: string
  capTableReportingAsOfDate: string
  capTableDaysSinceDue: number
  latestCapTableReportingId: string | null
  latestCapTableReportingAsOfDate: string
  coiExpiration: string
  coiExpirationStatus: string | null
  coiExpirationStatusLabel: string
  coiExpirationBoxLink: string
  eopExpiration: string
  eopExpirationStatus: string | null
  eopExpirationStatusLabel: string
  eopExpirationBoxLink: string
  borrowingBase: string
  funding: string
  borrowingBaseStatus: string | null
  borrowingBaseStatusLabel: string
  excessLoanAvailability: number
  requiredReports: string[]
  requiredReportsConfig: { documentName: string; frequency: string[]; dueDate: string | null }[]
  primaryUserEmail: string
  primaryUserFirstName: string
  isErpConnected: boolean
  erpName: string
  complianceNote: string
  totalOverdueReports: number
  listOfClientOverdueReports: string[]
  lastContactedCompliance: string
  currentAmount?: number
  currentAmountString?: string
  risk?: number
  riskString?: string
  lineTurn?: number
  lineTurnString?: string
  refreshedAt: string | null
  contractDate: string | null
}

export interface IClientComplianceData {
  data: IClientCompliance[]
  totals: {
    totalItems: number
    refreshedAt: string | null
  }
}

export const CLIENT_FOLDER_NAMES = {
  documents: 'Documents',
  underwriting: 'Underwriting',
  preUnderwriting: 'Pre-Underwriting',
  amendments: 'Executed Documents',
  lcr: 'LCR, UW Questions, Detailed Search',
  lsa: 'Executed Documents',
  warehouseWaivers: 'Landlord Waivers',
  ucc: 'Lien Searches _,Termination _,Filing, Search to Reflect, Good Standing Certificate',
  insurance: 'Company Insurance',
  payoff: 'Payoff',
}

export interface IAmendmentDetail {
  id: string
  clientName: string
  termName: string
  oldValue: string
  newValue: string
}

export interface IAmendment {
  id: string
  clientName: string
  createdAt: string
  effectiveDate: string
  amendmentType: ClientAmendmentType
  boxLink: string
  status: string
  clientAmendmentDetails: IAmendmentDetail[]
  participation: IParticipation
  participant: string
  summaryOfChanges?: string
  isTermChange: boolean
  isManual: boolean
  overadvanceId?: string
  overadvance?: IOveradvance
}

export interface IAmendmentsData {
  amendments: (IAmendment & IOveradvance)[]
  totalItems: number
}

export interface IInventoryIneligibilityFieldAliases {
  custom_1?: string
  custom_2?: string
  custom_3?: string
  custom_4?: string
  custom_5?: string
}
export enum TermLoanStatus {
  Future = 'Future',
  Active = 'Active',
  Terminated = 'Terminated',
}

export enum ClientSetupSections {
  LineUtilization = 'Line utilization',
  Collateral = 'Collateral',
  InterestRate = 'Interest rate',
  Fees = 'Fees',
  AdministrationInfo = 'Administration info',
  Reporting = 'Reporting',
}

export interface ClientTerms {
  id: string
  boxLink?: string
  effectiveDate: string
  restartTerm?: boolean
  loanType?: string
  contractDate?: string
  maturityDate?: string
  autoRenew?: string
  lockbox?: number
  wireFee?: number
  lockboxFee?: number
  facilityFee?: number
  terminationStartDate?: string
  terminationYear1?: number
  terminationYear2?: number
  terminationOngoing?: number
  interestRateBase?: number
  interestRateAbovePrime?: number
  interestPerDays?: number
  interestFloatDays?: number
  contractualFloatDays?: number
  nolv?: number
  arAdvance?: number
  inventoryAdvanceRateCost?: number
  rawMaterialsAdvanceRateCost?: number
  inventoryAdvanceRateNolv?: number
  minimumLineAmount?: string
  maxLineAmount?: string
  sublimit?: number
  pastInvoiceThreshold?: number
  crossAge?: number
  unusedLineFee?: number
  nextFacilityFeeDate?: string
  minimumInterestEffectiveDate?: string
  rmNolv?: number
  rmInventoryAdvanceRateNolv?: number
  noticePeriodEnd?: number
  noticePeriodEndDate?: string
}

export interface OtherClientTerms {
  interestRate?: number
  lenderLossPayee?: boolean
  additionallyInsured?: boolean
  eopExpirationDate?: string
  coiExpirationDate?: string
  gracePeriod?: number
  servicingFee?: number
  applyServicingFee?: boolean
}
const PAST_INVOICE_DATE_THRESHOLD_VALUES = ['30', '60', '90']

export enum CollateralSubSection {
  Inventory = 'inventory',
  AR = 'ar',
}

export enum FieldType {
  Select = 'select',
  Number = 'number',
  Date = 'date',
  Percent = 'percent',
  Boolean = 'boolean',
  Currency = 'currency',
  Text = 'text',
}

export interface IField {
  label?: string
  name: string
  type: 'currency' | 'percent' | 'date' | 'number' | 'select' | 'boolean' | 'text' | 'creatable'
  options?: { label: string; value: string }[]
  disabled?: boolean
  placeholder?: string
  size?: 'small' | 'medium' | 'large'
  minDate?: Date
  maxDate?: Date
  className?: string
}

export type ClientSetupField = {
  value: keyof ClientTerms | keyof OtherClientTerms
  dbName?: string
  label: string
  type: FieldType
  section: ClientSetupSections | null
  subSection?: CollateralSubSection
  disabled?: boolean
  options?: { label: string; value: string }[]
  excludeFromDb?: boolean
  placeholder?: string
}

export const clientTermsInfo: ClientSetupField[] = [
  {
    value: 'effectiveDate',
    label: 'Effective date',
    type: FieldType.Date,
    excludeFromDb: true,
    section: null,
  },

  {
    value: 'maxLineAmount',
    label: 'Max line amount',
    type: FieldType.Currency,
    section: ClientSetupSections.LineUtilization,
  },
  {
    value: 'minimumLineAmount',
    label: 'Min line amount',
    type: FieldType.Currency,
    section: ClientSetupSections.LineUtilization,
  },
  {
    value: 'minimumInterestEffectiveDate',
    label: 'Min interest waived until',
    type: FieldType.Date,
    section: ClientSetupSections.LineUtilization,
    placeholder: 'Optional',
  },
  {
    value: 'unusedLineFee',
    label: 'Unused line fee',
    type: FieldType.Percent,
    section: ClientSetupSections.LineUtilization,
  },

  {
    value: 'loanType',
    label: 'Deal type',
    type: FieldType.Select,
    section: ClientSetupSections.Collateral,
    options: Object.values(LOAN_TYPES).map((value) => ({
      value,
      label: value,
    })),
  },
  {
    value: 'arAdvance',
    label: 'AR advance',
    type: FieldType.Percent,
    section: ClientSetupSections.Collateral,
    subSection: CollateralSubSection.AR,
  },
  {
    value: 'pastInvoiceThreshold',
    label: 'Past invoice threshold',
    type: FieldType.Number,
    section: ClientSetupSections.Collateral,
    subSection: CollateralSubSection.AR,
    options: PAST_INVOICE_DATE_THRESHOLD_VALUES.map((value) => ({
      value,
      label: value,
    })),
  },
  {
    value: 'crossAge',
    label: 'Cross age',
    type: FieldType.Percent,
    section: ClientSetupSections.Collateral,
    subSection: CollateralSubSection.AR,
  },

  {
    value: 'inventoryAdvanceRateCost',
    label: 'Inventory advance cost',
    type: FieldType.Percent,
    section: ClientSetupSections.Collateral,
    subSection: CollateralSubSection.Inventory,
  },
  {
    value: 'nolv',
    label: 'NOLV',
    type: FieldType.Percent,
    section: ClientSetupSections.Collateral,
    subSection: CollateralSubSection.Inventory,
  },

  {
    value: 'inventoryAdvanceRateNolv',
    label: 'NOLV advance rate',
    type: FieldType.Percent,
    section: ClientSetupSections.Collateral,
    subSection: CollateralSubSection.Inventory,
  },
  {
    value: 'rawMaterialsAdvanceRateCost',
    label: 'Raw materials advance cost',
    type: FieldType.Percent,
    section: ClientSetupSections.Collateral,
    subSection: CollateralSubSection.Inventory,
  },
  {
    value: 'rmNolv',
    label: 'Raw material NOLV',
    type: FieldType.Percent,
    section: ClientSetupSections.Collateral,
    subSection: CollateralSubSection.Inventory,
  },
  {
    value: 'rmInventoryAdvanceRateNolv',
    label: 'Raw material NOLV advance rate',
    type: FieldType.Percent,
    section: ClientSetupSections.Collateral,
    subSection: CollateralSubSection.Inventory,
  },

  {
    value: 'sublimit',
    label: 'Inventory sublimit',
    type: FieldType.Percent,
    section: ClientSetupSections.Collateral,
    subSection: CollateralSubSection.Inventory,
  },
  {
    value: 'interestRateBase',
    label: 'Floor',
    type: FieldType.Percent,
    section: ClientSetupSections.InterestRate,
  },
  {
    value: 'interestRateAbovePrime',
    label: 'Margin above prime',
    type: FieldType.Percent,
    section: ClientSetupSections.InterestRate,
  },
  {
    value: 'interestPerDays',
    label: 'Interest (per days)',
    type: FieldType.Number,
    section: ClientSetupSections.InterestRate,
  },
  {
    value: 'interestFloatDays',
    label: 'Effective float days',
    type: FieldType.Number,
    section: ClientSetupSections.InterestRate,
  },
  {
    value: 'contractualFloatDays',
    label: 'Contractual float days',
    type: FieldType.Number,
    section: ClientSetupSections.InterestRate,
  },

  {
    value: 'wireFee',
    label: 'Wire fee',
    type: FieldType.Currency,
    section: ClientSetupSections.Fees,
  },
  {
    value: 'lockboxFee',
    label: 'Lockbox fee',
    type: FieldType.Currency,
    section: ClientSetupSections.Fees,
  },
  {
    value: 'facilityFee',
    label: 'Facility fee',
    type: FieldType.Percent,
    section: ClientSetupSections.Fees,
  },
  {
    value: 'nextFacilityFeeDate',
    label: 'Next facility fee (after funding)',
    type: FieldType.Date,
    section: ClientSetupSections.Fees,
  },

  {
    value: 'terminationYear1',
    dbName: 'termination_year_1',
    label: 'Termination fee (Year 1)',
    type: FieldType.Percent,
    section: ClientSetupSections.Fees,
  },
  {
    value: 'terminationOngoing',
    dbName: 'termination_ongoing',
    label: 'Termination fee (Ongoing)',
    type: FieldType.Percent,
    section: ClientSetupSections.Fees,
  },
  {
    value: 'terminationYear2',
    dbName: 'termination_year_2',
    label: 'Termination fee (Year 2) (if applicable)',
    type: FieldType.Percent,
    section: ClientSetupSections.Fees,
  },
  {
    value: 'contractDate',
    label: 'Contract date',
    type: FieldType.Date,
    section: ClientSetupSections.AdministrationInfo,
  },
  {
    value: 'terminationStartDate',
    label: 'Term start date',
    type: FieldType.Date,
    section: ClientSetupSections.AdministrationInfo,
    disabled: true,
  },
  {
    value: 'maturityDate',
    label: 'Maturity date',
    type: FieldType.Date,
    section: ClientSetupSections.AdministrationInfo,
  },
  {
    value: 'noticePeriodEnd',
    label: 'Notice before maturity (days)',
    type: FieldType.Number,
    section: ClientSetupSections.AdministrationInfo,
  },
  {
    value: 'noticePeriodEndDate',
    label: 'Last day to give notice',
    type: FieldType.Date,
    section: ClientSetupSections.AdministrationInfo,
    disabled: true,
  },
  {
    value: 'lockbox',
    label: 'Lockbox',
    type: FieldType.Number,
    section: ClientSetupSections.AdministrationInfo,
  },
  {
    value: 'autoRenew',
    label: 'Auto renew',
    type: FieldType.Select,
    section: ClientSetupSections.AdministrationInfo,
    options: [
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
  },
]

const otherClientTermsInfo: ClientSetupField[] = [
  {
    value: 'interestRate',
    label: 'Rate',
    type: FieldType.Percent,
    disabled: true,
    section: ClientSetupSections.InterestRate,
  },
  {
    value: 'coiExpirationDate',
    label: 'COI expiration',
    type: FieldType.Date,
    section: ClientSetupSections.Reporting,
  },
  {
    value: 'eopExpirationDate',
    label: 'EOP expiration',
    type: FieldType.Date,
    section: ClientSetupSections.Reporting,
  },
  {
    value: 'additionallyInsured',
    label: 'Additionally insured',
    type: FieldType.Boolean,
    section: ClientSetupSections.Reporting,
  },
  {
    value: 'lenderLossPayee',
    label: 'Lender loss payee',
    type: FieldType.Boolean,
    section: ClientSetupSections.Reporting,
  },
  {
    value: 'applyServicingFee',
    label: 'Apply servicing fee',
    type: FieldType.Boolean,
    section: ClientSetupSections.Fees,
  },
  {
    value: 'servicingFee',
    label: 'Servicing fee',
    type: FieldType.Currency,
    section: ClientSetupSections.Fees,
  },
  {
    value: 'gracePeriod',
    label: 'Grace period',
    type: FieldType.Number,
    section: ClientSetupSections.Fees,
  },
]

export const allClientTermsInfo = [...otherClientTermsInfo, ...clientTermsInfo]

export enum ClientAmendmentStatus {
  Pending = 'Pending',
  Completed = 'Completed',
}

export enum ClientAmendmentType {
  Legal = 'Legal',
  NonLegal = 'Non-Legal',
  Participation = 'Participation',
}

export interface WatchlistDetail {
  riskRating: number
  percentApLess_60: number
  grossRevenue_6MonthRollingAvg: number
  ebitda_6MonthRollingAvg: number
  grossMarginRollingAvg: number
  yoyNetRevGrowth: number
  percentArLess_60: number
  arTop_5Conc: number
  lineTurn: number
  cashBurn: number
  currentRatio: number
  dilution: number
  weeksOfSupply: number
  inventoryHealthRating: number
}

export interface IRiskRatingSummary {
  id: string
  clientInfo: IClientInfo
  riskRating: number
  lineTurn: number
  cashBurn: number
  grossMarginRollingAvg: number
  percentApLess60: number
  ebitda6MonthRollingAvg: number
  currentRatio: number
  percentArLess60: number
  dilution: number
  arTop5Conc: number
  weeksOfSupply: number
  loanBalance: number
  riskRatingRawData: IRiskRatingSummary[]
}

export interface IWatchlist {
  id: string
  clientName: string
  maturityDate: string
  loanBalance: {
    loanBalance: number
  }
  riskRatingTier: string
  riskRatingRawScore: WatchlistDetail
  riskRatingRawData: IRiskRatingRawData
  inventoryHealthRating: number | null
}

export interface IWatchlistData {
  data: IWatchlist[]
  totalItems: number
}

export interface IRiskRatingSummaryData {
  data: IRiskRatingSummary[]
  totalItems: number
  dateOptions: { value: string; label: string }[]
}

export enum WatchListRiskRatingTier {
  Workout = 'Workout',
  Watchlist = 'Watchlist',
  Monitor = 'Monitor',
}

export interface IRiskRatingDetail extends WatchlistDetail {}

export interface IRiskRatingRawData extends WatchlistDetail {
  avgEbitda: number
  cashAndCashEquivalents: number
  t3mGrossProfit: number
  t3mNetRevenue: number
  trailingSixNetRev: number
  pyTrailingSixNetRev: number
  totalCurrentAssets: number
  totalCurrentLiabilities: number
  t3mGrossRevenue: number
  t12mCogs: number
  inventory: number
  totalGrossAr: number
  ar1To60Days: number
  totalAp: number
  ap0To60Days: number
  averageLoanBalance: number
  averageCollections: number
  inventoryWoS: number
  daysSinceLandedGt180: number
  daysUntilExpirationLt90: number
  salesAndOnHandVariance: number
  maxSalesMonths: number
}

export interface IRiskRating {
  data: IRiskRatingRawData
  scores: IRiskRatingDetail
  portfolioScores: IRiskRatingDetail
  portfolioData: IRiskRatingRawData
  date: string
  loanBalance: number
}

export interface IRiskRatingData {
  data: IRiskRating[]
}

export const RISK_METRIC_OPTIONS: {
  value: keyof IRiskRatingDetail
  label: string
  type: string
}[] = [
  {
    value: 'riskRating',
    label: 'Risk rating',
    type: 'number',
  },
  {
    value: 'lineTurn',
    label: 'Line turn',
    type: 'number',
  },
  {
    value: 'cashBurn',
    label: 'Cash burn',
    type: 'number',
  },
  {
    value: 'grossMarginRollingAvg',
    label: '6M Gross margin',
    type: 'percent',
  },
  {
    value: 'yoyNetRevGrowth',
    label: 'YoY Net rev',
    type: 'percent',
  },
  {
    value: 'percentApLess_60',
    label: '% AP < 60',
    type: 'percent',
  },
  {
    value: 'grossRevenue_6MonthRollingAvg',
    label: '6M Gross revenue',
    type: 'amount',
  },
  {
    value: 'ebitda_6MonthRollingAvg',
    label: '6M EBITDA',
    type: 'amount',
  },
  {
    value: 'currentRatio',
    label: 'Current ratio',
    type: 'number',
  },
  {
    value: 'percentArLess_60',
    label: '% AR < 60',
    type: 'percent',
  },
  {
    value: 'arTop_5Conc',
    label: 'AR Top 5 Conc',
    type: 'percent',
  },

  {
    value: 'dilution',
    label: 'Dilution',
    type: 'percent',
  },
  {
    value: 'weeksOfSupply',
    label: 'Weeks of supply',
    type: 'number',
  },
]

export const RISK_METRIC_UNDERLYING_DATA: {
  value: keyof IRiskRatingRawData
  label: string
  type: string
  originalValue: keyof IRiskRatingDetail
}[] = [
  {
    value: 'averageCollections',
    label: 'T3M Collections',
    type: 'amount',
    originalValue: 'lineTurn',
  },
  {
    value: 'averageLoanBalance',
    label: '3M Avg Loan Balance',
    type: 'amount',
    originalValue: 'lineTurn',
  },
  {
    value: 'avgEbitda',
    label: 'Avg EBITDA',
    type: 'amount',
    originalValue: 'cashBurn',
  },
  {
    value: 'cashAndCashEquivalents',
    label: 'Cash on Hand',
    type: 'amount',
    originalValue: 'cashBurn',
  },
  {
    value: 't3mNetRevenue',
    label: 'T3M Net Revenue',
    type: 'amount',
    originalValue: 'grossMarginRollingAvg',
  },
  {
    value: 't3mGrossProfit',
    label: 'T3M Gross Profit',
    type: 'amount',
    originalValue: 'grossMarginRollingAvg',
  },
  {
    value: 'trailingSixNetRev',
    label: 'Trailing 6M Net Revenue',
    type: 'amount',
    originalValue: 'yoyNetRevGrowth',
  },
  {
    value: 'pyTrailingSixNetRev',
    label: 'PY trailing 6M Net Revenue',
    type: 'amount',
    originalValue: 'yoyNetRevGrowth',
  },
  {
    value: 'totalAp',
    label: 'Total AP',
    type: 'amount',
    originalValue: 'percentApLess_60',
  },
  {
    value: 'ap0To60Days',
    label: 'AP 0-60 Days',
    type: 'amount',
    originalValue: 'percentApLess_60',
  },
  {
    value: 'totalCurrentAssets',
    label: 'Total Current Assets',
    type: 'amount',
    originalValue: 'currentRatio',
  },
  {
    value: 'totalCurrentLiabilities',
    label: 'Total Current Liabilities',
    type: 'amount',
    originalValue: 'currentRatio',
  },
  {
    value: 'totalGrossAr',
    label: 'Total Gross AR',
    type: 'amount',
    originalValue: 'percentArLess_60',
  },
  {
    value: 'ar1To60Days',
    label: 'AR 1-60 Days',
    type: 'amount',
    originalValue: 'percentArLess_60',
  },
  {
    value: 'totalGrossAr',
    label: 'Total Gross AR',
    type: 'amount',
    originalValue: 'arTop_5Conc',
  },
  {
    value: 't3mGrossRevenue',
    label: 'T3M Gross Revenue',
    type: 'amount',
    originalValue: 'dilution',
  },
  {
    value: 't3mNetRevenue',
    label: 'T3M Net Revenue',
    type: 'amount',
    originalValue: 'dilution',
  },
  {
    value: 't12mCogs',
    label: 'T12M COGS',
    type: 'amount',
    originalValue: 'weeksOfSupply',
  },
  {
    value: 'inventory',
    label: 'Inventory',
    type: 'amount',
    originalValue: 'weeksOfSupply',
  },
]

export const RISK_METRIC_UNDERLYING_DATA_WATCHLIST: {
  value: keyof IRiskRatingRawData
  label: string
  type: string
  originalValue: keyof IRiskRatingDetail
}[] = [
  {
    value: 'lineTurn',
    label: 'Line Turn',
    type: 'number',
    originalValue: 'lineTurn',
  },
  {
    value: 'cashBurn',
    label: 'Cash Burn',
    type: 'amount',
    originalValue: 'cashBurn',
  },
  {
    value: 'grossMarginRollingAvg',
    label: '3M Gross Margin',
    type: 'percent',
    originalValue: 'grossMarginRollingAvg',
  },
  {
    value: 'percentApLess_60',
    label: '% AP Under 60',
    type: 'percent',
    originalValue: 'percentApLess_60',
  },
  {
    value: 'ebitda_6MonthRollingAvg',
    label: '6M EBITDA',
    type: 'amount',
    originalValue: 'ebitda_6MonthRollingAvg',
  },
  {
    value: 'currentRatio',
    label: 'Current Ratio',
    type: 'number',
    originalValue: 'currentRatio',
  },
  {
    value: 'percentArLess_60',
    label: '% AR Under 60',
    type: 'percent',
    originalValue: 'percentArLess_60',
  },
  {
    value: 'dilution',
    label: 'Dilution',
    type: 'percent',
    originalValue: 'dilution',
  },
  {
    value: 'arTop_5Conc',
    label: 'AR Top 5 Conc',
    type: 'percent',
    originalValue: 'arTop_5Conc',
  },
  {
    value: 'weeksOfSupply',
    label: 'WoS',
    type: 'number',
    originalValue: 'weeksOfSupply',
  },
  {
    value: 'yoyNetRevGrowth',
    label: 'YoY Net Rev',
    type: 'percent',
    originalValue: 'yoyNetRevGrowth',
  },
  ...RISK_METRIC_UNDERLYING_DATA,
  {
    value: 'inventoryWoS',
    label: 'WoS',
    type: 'number',
    originalValue: 'inventoryHealthRating',
  },
  {
    value: 'daysSinceLandedGt180',
    label: 'Days Since Landed > 180',
    type: 'amount',
    originalValue: 'inventoryHealthRating',
  },
  {
    value: 'daysUntilExpirationLt90',
    label: 'Days Until Expiration < 90',
    type: 'amount',
    originalValue: 'inventoryHealthRating',
  },
  {
    value: 'salesAndOnHandVariance',
    label: 'Sales vs On Hand Variance',
    type: 'percent',
    originalValue: 'inventoryHealthRating',
  },
  {
    value: 'maxSalesMonths',
    label: 'Max Sales Months',
    type: 'number',
    originalValue: 'inventoryHealthRating',
  },
]

export interface IClientCashFlow {
  header: string
  financialsType: FinancialsType
  recordDate: string
  mostRecentCash: number
  avgOperatingCashFlow: number
  monthsOfCashOnHandOperatingCashFlow: number | string
  monthsOfCashOnHandEBITDA: number | string
  avgEBITDA: number
  avgNetIncome: number
  changeInAccountsReceivableNet: number
  changeInInventory: number
  changeInPrepaidExpenses: number
  changeInAccountsPayable: number
  changeInAccruedExpenses: number
  changeInTaxesPayable: number
  changeInOtherShortTermDebt: number
  changeInDwightFundingLoan: number
  changeInNetPpe: number
  changeInAdditionalPaidInCapital: number
  changeInConvertibleNotesEquity: number
  changeInOther: number
  changeInCash: number
  beginingCash: number
  endingCash: number
}

export interface IClientCashFlowData {
  asOfDate: string
  numberOfMonthLast: number
  minDateLast: string
  maxDateLast: string
  numberOfMonthNext: number
  minDateNext: string
  maxDateNext: string
  summary: { [key in FinancialsType]: IClientCashFlow } | {}
  headers: []
  data: IClientCashFlow[]
}

export interface IInventoryHealth {
  clientName: string
  recordDate: string
  weeksOfSupply: number | null
  daysSinceLandedGt180Pct: number | null
  daysUntilExpirationLt90Pct: number | null
  avgSalesMonthsPctOfMaxMonths: number | null
  salesAndOnHandVariance: number | null
  weeksOfSupplyRating: number | null
  daysSinceLandedRating: number | null
  daysUntilExpirationRating: number | null
  avgSalesMonthsRating: number | null
  salesOnHandVarianceRating: number | null
  industry: string | null
  inventoryRating: number | null
  totalWeeklySales: number | null
  totalValue: number | null
  weightedSalesMonths: number | null
  maxSalesMonths: number | null
  daysSinceLandedGt180: number | null
  daysUntilExpirationLt90: number | null
  createdAt: string
  updatedAt: string
}

export interface IInventoryHealthData {
  data: {
    recordDate: string
    inventoryHealth: IInventoryHealth
    portfolioAverages: IInventoryHealth
  }[]
}

export const INVENTORY_HEALTH_METRIC_OPTIONS: {
  value: keyof IInventoryHealth
  score: keyof IInventoryHealth
  label: string
  type: string
}[] = [
  {
    value: 'inventoryRating',
    score: 'inventoryRating',
    label: 'Inventory Health Rating',
    type: 'number',
  },
  {
    score: 'weeksOfSupplyRating',
    value: 'weeksOfSupply',
    label: 'Weeks of Supply',
    type: 'number',
  },
  {
    value: 'daysSinceLandedGt180Pct',
    score: 'daysSinceLandedRating',
    label: 'Days Since Landed > 180',
    type: 'percent',
  },
  {
    value: 'daysUntilExpirationLt90Pct',
    score: 'daysUntilExpirationRating',
    label: 'Days Until Expiration < 90',
    type: 'percent',
  },
  {
    value: 'avgSalesMonthsPctOfMaxMonths',
    score: 'avgSalesMonthsRating',
    label: 'Inv. with Max Months of Sales',
    type: 'percent',
  },
  {
    value: 'salesAndOnHandVariance',
    score: 'salesOnHandVarianceRating',
    label: 'Sales vs On Hand Variance',
    type: 'number',
  },
]

export const INVENTORY_HEALTH_METRIC_UNDERLYING_DATA: {
  value: keyof IInventoryHealth
  originalValue: keyof IInventoryHealth
  label: string
  type: string
}[] = [
  {
    value: 'totalWeeklySales',
    originalValue: 'weeksOfSupply',
    label: 'Total Weekly Sales',
    type: 'amount',
  },
  {
    value: 'totalValue',
    originalValue: 'weeksOfSupply',
    label: 'Total Inventory',
    type: 'amount',
  },
  {
    value: 'daysSinceLandedGt180',
    originalValue: 'daysSinceLandedGt180Pct',
    label: 'Days Since Landed > 180 ($)',
    type: 'amount',
  },
  {
    value: 'totalValue',
    originalValue: 'daysSinceLandedGt180Pct',
    label: 'Total Inventory',
    type: 'amount',
  },
  {
    value: 'daysUntilExpirationLt90',
    originalValue: 'daysUntilExpirationLt90Pct',
    label: 'Days Until Expiration < 90 ($)',
    type: 'amount',
  },
  {
    value: 'totalValue',
    originalValue: 'daysUntilExpirationLt90Pct',
    label: 'Total Inventory',
    type: 'amount',
  },
  {
    value: 'maxSalesMonths',
    originalValue: 'avgSalesMonthsPctOfMaxMonths',
    label: 'Max Sales Months',
    type: 'number',
  },
  {
    value: 'weightedSalesMonths',
    originalValue: 'avgSalesMonthsPctOfMaxMonths',
    label: 'Avg Sales Months',
    type: 'number',
  },
]

export interface IClientBanner {
  id: string
  title: string
  description: string
  icon: string | null
  color: string
  link: string | null
  startDate: string
  endDate: string
}

export interface IClientLoanLedgerWire {
  isParticipantWire: boolean
  item: IAccountActivity | IParticipationWire
}

export interface IClientLoanLedger {
  id: string
  clientName: string
  recordDate: string
  loanBalance: number
  loanBalancePrev: number
  fundsIn: number
  fundsOut: number
  fees: number
  dwightExposure: number
  totalFees: number
  participationLoanExposure: IParticipationLoanExposure[]
  wires: IClientLoanLedgerWire[]
}

export interface IClientLoanLedgerData {
  data: IClientLoanLedger[]
  totalCount: number
}

export const RISK_RATING_COLOR_MAPPING = [
  { min: 6, max: null, colors: { backgroundColor: '#66B48540', color: '#66B485' } },
  { min: 5, max: 6, colors: { backgroundColor: '#EEFFEB', color: '#8DB43C' } },
  { min: 4, max: 5, colors: { backgroundColor: '#FFCB001A', color: '#765E00' } },
  { min: 3, max: 4, colors: { backgroundColor: '#FBF0E0', color: '#E78B0A' } },
  { min: 2, max: 3, colors: { backgroundColor: '#E539353D', color: '#FF4141' } },
  { min: null, max: 2, colors: { backgroundColor: '#FFA7A7', color: '#761818' } },
  { min: null, max: null, colors: { backgroundColor: '#EDF2F7', color: '#718096' } },
]

export enum ClientIndustries {
  Apparel = 'Apparel',
  Beauty = 'Beauty',
  Beverages = 'Beverages',
  ChildBaby = 'Child/Baby',
  Food = 'Food',
  Home = 'Home',
  Lifestyle = 'Lifestyle',
  Other = 'Other',
  Outdoor = 'Outdoor',
  PetProducts = 'Pet Products',
}
